import React from "react";
import { Modal, Button } from 'react-bootstrap';

export default function CommonModal(props) {
    const cancelBtnClick = () => {
        props.setShowPopup(false);
        props.setConfirmatioData('no')
    }
    const saveBtnClick = () => {
        props.setShowPopup(false);
        props.setConfirmatioData('yes')

    }
    const styles = {
        height: {
            height: props.height
        }
    };
    return (
        <div>
            <div className="App p-4">
                <Modal style={styles.height} size="md" centered show={props.show}>
                    {props.closeBtn ?
                        <Modal.Header closeButton onClick={() => {props.setShowPopup(false)}}>
                            <Modal.Title>{props.title}</Modal.Title>
                        </Modal.Header>
                        :
                        <Modal.Header>
                            <Modal.Title>{props.title}</Modal.Title>
                        </Modal.Header>
                    }
                    <Modal.Body style={styles.height}>
                        <div>
                            {
                                (props.contentlistID!==undefined && props.contentlistID!=="") ?
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Duration</th>
                                    <th>Expiry Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {props.playlistcontentlist && props.playlistcontentlist[props.contentlistID] ?
                                    props.playlistcontentlist[props.contentlistID].map((content) => (
                                        <tr key={content.id}>
                                            <td>{content.name}</td>
                                            <td>{content.duration}</td>
                                            <td>{content.expiry_date}</td>
                                        </tr>
                                    )) :
                                    <tr>
                                        <td colSpan="3">No content available</td>
                                    </tr>
                                }
                                </tbody>
                            </table>:
                            props.body}
                        </div>

                    </Modal.Body>

                    {(!props.noheader && props.footer != '') ? <Modal.Footer>
                        <Button className="btn-confirm" onClick={saveBtnClick}>Yes</Button>
                        <Button className="btn-confirm" onClick={cancelBtnClick}>No</Button>
                    </Modal.Footer> : <></>
                    }
                </Modal>
            </div>
        </div>
    );
}