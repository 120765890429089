import React, {useEffect, useState} from "react";
import {Dropzone, FileMosaic} from "@files-ui/react";
import axios from "axios";
import {ProgressBar} from 'react-bootstrap';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './uploadfile.scss';
import ProgressBarUplaod from './ProgressBarUplaod';

let fileArray  = [];
const UploadFile = (props) => {
  const contentMgmKeys=props.content_mgm_keys
  const [fileUploadData, setFileData] = useState('');
  const [updateMaterial, setUpdateMaterialJson] = useState([]);
  const [files, setFiles] = useState([]);
  const [urlFolderId, setUrlFolderId] = useState(null);
  const [urlType, setUrlType] = useState(null);
  const [isDocProgress, setIsDocProgress] = useState(null);
  const [docProgress, setdocProgress] = useState(null);
  const[errorMessage,setErrorMessage] = useState(false)
  const[disabledStatus,setDisabledStatus] = useState(false)
  const[uploadStart,setUploadStart] = useState(false)
  const max_file_char_limit = 60;
  let fileuploading = 0
  let max_file_upload_limit = 100
  let totalFileSize = 0;
  if(props.max_file_size){
    totalFileSize = props.max_file_size - props.current_file_size
  }
  const removeCancelBtn = (i)=>{
    let divElements = document.getElementsByClassName("file-mosaic-main-layer-header");
    if (divElements.length > 0) {
      divElements[i].style.display='none';
    } else {
      console.log("No elements found with the specified class name.");
    }
  }
  const checkInvalidType = () => {
    const invalidFiles = [];
    files.forEach((file) => {
      console.log('file.type is ===', file.type)
      if (!allowedTypes.includes(file.type)) {
        invalidFiles.push(file.name);
      }
      if (file.size > totalFileSize) {
        invalidFiles.push(file.name);
      }
      if(file.name){
        let nameArray = file.name.split('.');
        if(nameArray[0].length > max_file_char_limit){
          invalidFiles.push(file.name+ ` (Maximum ${max_file_char_limit} characters allowed)`);
        }
      }
      if (file.type.startsWith("image/") && file.size > props.image_file_max_size) {
        invalidFiles.push(file.name);
      }else if( file.size > props.media_file_max_size){
        invalidFiles.push(file.name);
      }
    });
    return invalidFiles;
  }
  const Msg = (data) => {
    return (
      <>
        <div>
           Below file format not supported or exceeds max file size
        </div>
        <ol>
          {data.map((file, index) => (
              <li>{file}</li>
            ))}
        </ol>
      </>
    )
  }
  const uploadMedia = (i) => {
    let invalidData = checkInvalidType();
    if (invalidData.length > 0) {
      notify(Msg(invalidData), 'error');
      return;
    }
   if(fileArray[i] !== undefined) {
      let pattern = /^[a-z\d\-_\d\-.\s]+$/i
      let isValid = pattern.test(fileArray[i].file['name']);
      if (!isValid) {
        notify('Only letters,number, -, . and _ allowed','error');
        return;
      }
      let postData = {}
      let parentFolderID = null;
      if (fileArray[i].file.type.includes(urlType)) {
        parentFolderID = urlFolderId;
      }
      postData =
      {
        'material': {
          'mime_type': fileArray[i].file['type'],
          'name': fileArray[i].file['name'],
          'parent_folder_id': parentFolderID,
          'isDocImg': false,
        },
        'file': fileArray[i].file,
      }
      if (fileArray[i].file['type'].startsWith("image/") || (fileArray[i].file['type'].startsWith("video/") && fileArray[i].file['type']!= 'video/quicktime' )) {
        postData.thumbnail = true;
      }
      document.getElementById('uploadBtn').classList.add("disabled")
      removeCancelBtn(i);
      setDisabledStatus(true)
      uploadFileData(postData, fileUploadAsync)
    }
  };
  const uploadDuplicateMedia = (type,name) => {
    let newFiles=  files.filter((x) => x.name === name);
    newFiles.forEach((data) => {
      let pattern = /^[a-z\d\-_\d\-.\s]+$/i
      let isValid = pattern.test(data.file['name']);
      if (!isValid) {
        notify('Only letters, number, . and _ allowed','error');
        return;
      }
      let postData = {}
      let parentFolderID = null;
      if (data.file.type.includes(urlType)) {
        parentFolderID = urlFolderId;
      }
      postData =
          {
            'material': {
              'mime_type': data.file['type'],
              'name': data.file['name'],
              'parent_folder_id': parentFolderID,
              'isDocImg': false,
              'option_type':type,
            },
            'file': data.file,
          }
      if (data.file['type'].startsWith("image/") || (data.file['type'].startsWith("video/") && data.file['type'] !== 'video/quicktime' )) {
        postData.thumbnail = true;
      }
      uploadFileData(postData, fileUploadAsync)
    })
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUrlFolderId(urlParams.get('folder_id'))
    if(urlParams.get('type') === 'sound'){
      setUrlType('audio')
    }else{
      setUrlType(urlParams.get('type'))
    }

    localStorage.setItem('filesCount', 0)
  }, [])

  const base64ToBlob = (base64, mime) => {
    var data = atob(base64);
    var buffer = new ArrayBuffer(data.length);
    var bArray = new Uint8Array(buffer);
    for (var i = 0; i < data.length; i++) {
      bArray[i] = data.charCodeAt(i);
    }
    if (window.MozBlobBuilder) {
      var bb = new MozBlobBuilder();
      bb.append(bArray.buffer);
      var blob = bb.getBlob(mime);
      return blob;
    } else {
      return new Blob([bArray], { type: mime });
    }
  };
  const createThumbnailBlob = (param, callback) => {
    var size = param.size;
    var file = param.file;
    var newblob = param.newblob;
    var blob = ''
    var $canvas = $('.resize_canvas');
    if ($canvas.length === 0) {
      $canvas = $('<canvas>').attr({
        'class': 'resize_canvas',
        width: size.width,
        height: size.height
      });
      $(document.body).append($canvas);
    }
    // var deferred = new Deferred();
    var reader = new FileReader();
    reader.onload = function (e) {
      var currentEle
      if(e.target.result && e.target.result.includes('video')){
        var video = $('<video />', {
          src: newblob ? newblob : e.target.result,
          type: 'video/mp4',
          controls: false
        });
        currentEle=video[0]
        video[0].onloadeddata=loadData
        video[0].muted = true
        video[0].play()
      }
      else{
        var img = new Image();
        if (newblob) {
          img.src = newblob
        } else {
          img.src = e.target.result;
        }
        currentEle=img
        img.onload=loadData
      }
       function loadData(e) {
        if (currentEle.tagName=='VIDEO') {
          $(video)[0].muted = true
        }
        console.log('$canvas[0] is ------', $canvas[0])
        var ctx = $canvas[0].getContext('2d');
        ctx.clearRect(0, 0, $canvas.outerWidth(), $canvas.outerHeight());
        ctx.drawImage(currentEle, 0, 0, $canvas.outerWidth(), $canvas.outerHeight());
        var url = $canvas[0].toDataURL();
        var data = url.split(',')[1];
        if (newblob) {
          data = newblob.split(',')[1]
          blob = base64ToBlob(data, file.type);
        } else {
          blob = base64ToBlob(data, file.type);
        }
        if (file.docID) {
          blob.docID = file.docID;
        }
        console.log('blob data is ===', blob)
        let thumbnailData = {
          file: blob,
          mainJSON: param.thumbJSON
        }
        if (blob) {
          console.log("Thumbnail upload...");
          callback(thumbnailData);
        }
        // deferred.call(blob);
      };
      currentEle.onerror = function (e) {
        console.log('reader=====error=======', e)
        // deferred.fail();
      };
    };
    reader.onerror = function (e) {
      // deferred.fail();
      console.log('reader=====error2=======', e)

    }; blob
    console.log('reader============', reader)
    reader.readAsDataURL(file);
    return blob;
  };
  const showProgressFile = (fileName, data) => {
    document.getElementById(fileName).getElementsByTagName("div")[0].style.width = data + '%';
  }
  /*
   *  For AKAMAI UPLOADER
   */
  const ajaxAkamaiFileUploadAsync = (params) => {
    console.log("ajaxAkamaiFileUploadAsync",params);
    var formData = new FormData();
    formData.append("material", params.file);
    var xhr = new XMLHttpRequest();
    if (xhr.upload) {
      xhr.upload.onprogress = function (evt) {
        if (evt.lengthComputable) {
          var progress = Math.ceil((evt.loaded / evt.total) * 100);
          console.log("Uploading: " + progress + "%")
          if (params.file && params.file.name) {
            showProgressFile(params.file.name, progress);
          }
        }
      };
    } else {
      console.log("xhr doesn't support upload progress");
    }
    xhr.open('PUT', params.mainJSON.postUrl + params.mainJSON.uri_path, true);
    var self = this;
    xhr.onreadystatechange = function (e) {
      if (this.readyState === 4) {
        if (this.status === 201 || this.status === 200) {
          console.log(this.status, 'return here',params.file.name);
          if(params.file && params.file.name !== undefined){
            return doCheckUploadFinish(params);
          }
        }
      }
    };
    xhr.setRequestHeader('X-Akamai-ACS-Action', params.mainJSON.action_header);
    xhr.setRequestHeader('X-Akamai-ACS-Auth-Data', params.mainJSON.signature1);
    xhr.setRequestHeader('X-Akamai-ACS-Auth-Sign', params.mainJSON.signature2);
    console.log('formData is ==', formData)
    xhr.send(formData);
  };
  //1 st case to upload
  const ajaxRiakFileUploadAsync = (params) => {
    let url = params.mainJSON.post_url;
    let formData = new FormData();
    formData.append('cookie', document.cookie);
    formData.append('signature', params.mainJSON.signature);
    formData.append('file', params.file);
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    var self = this;
    xhr.onreadystatechange = function (xhr, reason) {
      if (this.readyState === 4) {
        if (this.status === 200) {
          return doCheckUploadFinish(params);
        } else {
          var e = new exports.Error.CommandException({
            xhr: xhr,
            optReason: reason,
            url: url
          });
          // deferred.fail(e);
        }
      }
    };
    xhr.send(formData);
  };

  /**
 Jws s3 file uploader 3rd upload
 **/
  const ajaxJwsS3FileUploadAsync = (params) => {

    let formData = params.file;
    let xhr = new XMLHttpRequest();
    // xhr.withCredentials = true;

    if (xhr.upload) {
      xhr.upload.onprogress = function (evt) {
        if (evt.lengthComputable) {
          var progress = Math.ceil((evt.loaded / evt.total) * 100);
          console.log("Uploading: " + progress + "%")
          if (params.file && params.file.name) {
            showProgressFile(params.file.name, progress);
          }
        }
      };
    } else {
      //LOG("xhr doesn't support upload progress");
      console.log("xhr doesn't support upload progress")
    }

    xhr.open('PUT', params.mainJSON.post_url, true);
    var self = this;
    xhr.onreadystatechange = function (e) {
      if (this.readyState === 4) {
          return doCheckUploadFinish(params);
      }
    };
    xhr.setRequestHeader('X-Auth-Token', params.mainJSON.auth_token);
    // xhr.withCredentials = true;
    xhr.send(formData);
  };
  //4th upload fn
  const ajaxS3FileUploadAsync = (params) => {
    let formData = new FormData();
    formData.append('key', params.mainJSON.key);
    formData.append(params.mainJSON.form_key, params.mainJSON.access_key);
    formData.append('acl', params.mainJSON.acl);
    formData.append('success_action_redirect', params.mainJSON.success_action_redirect);
    formData.append('policy', params.mainJSON.policy);
    formData.append('signature', params.mainJSON.signature);
    formData.append('bucket', params.mainJSON.bucket);
    formData.append('Content-Type', params.mainJSON.file.type);
    formData.append('file', params.file);
    let xhr = new XMLHttpRequest();
    xhr.open('POST', params.mainJSON.postUrl, true);
    let self = this;
    xhr.onreadystatechange = function (e) {
      if (this.readyState === 4) {
          return doCheckUploadFinish(params);
      }
    };
    xhr.send(formData);
  };

  const fileUploadAsync = (params) => {
    if (params.mainJSON.use_uploader) {
      return ajaxRiakFileUploadAsync(params);
    } else {
      if (params.mainJSON.content_storage == 'akamai') {
        return ajaxAkamaiFileUploadAsync(params);
      } else if (params.mainJSON.content_storage == 'jws_s3') {
        return ajaxJwsS3FileUploadAsync(params);
      } else {
        return ajaxS3FileUploadAsync(params);
      }
    }
  };

  function doCheckUploadFinish(params) {
    document.getElementById('uploadBtn').innerHTML = 'Generating...'
    axios
      .post(params.mainJSON.success_action_redirect, '', {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(result => {
        if (result.data.finish_upload) {
          console.log('inside finish_upload')
          if (window.NoOfmigrationFile !== undefined) {
            console.log('inside NoOfmigrationFile')
            window.migrationFileCounter += 1;
          }
          console.log('params.mainJSON.isDocImg is===', params.mainJSON.isDocImg)
          if (!params.file.type.startsWith("image/") && !params.file.type.startsWith("video/") && !params.file.type.startsWith("audio/") && !params.file.type.includes("zip") && !params.file.type.includes("pdf") ) {
            triggerFileCreation(result.data);
            return result.data;
          }
        }
        callURLJSON(params);
        if (result.data.transcoder_status == 0) return result;   // not transcorder
        if (result.data.transcoder_status == -1) return result;   // transcorder error
        if (result.data.transcoder_status == 3) return result;   // trasscorder finish
      })
      .catch(error => {
        console.log(error)
        document.getElementById('uploadBtn').innerHTML = 'Upload'
      });
  }
  const callURLJSON = (params) => {
    let url = `/v1/materials/${params.mainJSON.material_id}/url.json`;
    document.getElementById('uploadBtn').innerHTML = 'Finishing...'
    axios
      .get(url, {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        console.log('url json is ====', res.data)
        let parseData = {}
        if(params.file.type.includes("zip") || params.file.type.includes("pdf")){
          parseData = { "duration":0, "width": 0, "height": 0 }
          updateMaterialJson(params, parseData);
        }
        else if (params.file.type.startsWith("image/")) {
          var img = new Image();
          img.onload = function () {
            var width = img.width;
            var height = img.height;
            parseData = { "width": width, "height": height }
            updateMaterialJson(params, parseData);
          };
          img.src = res.data.url;
        } else if (params.file.type.startsWith("video/")) {
          const video = document.createElement('video');
          try {
            video.load();
            video.src = res.data.url;
            video.onloadedmetadata = () => {
              const durationInSeconds = video.duration;
              console.log('video duration is ===', durationInSeconds.toFixed(2));
              parseData = { "duration": parseFloat(durationInSeconds.toFixed(2)), "width": video.videoWidth, "height": video.videoHeight }
              updateMaterialJson(params, parseData);
            };
          } catch (error) {
            alert('Error loading the video: ' + error.message);
          }

        } else if (params.file.type.startsWith("audio/")) {
          const audio = document.createElement('audio');
          try {
            audio.load();
            audio.src = res.data.url;
            audio.onloadedmetadata = () => {
              const durationInSeconds = audio.duration;
              console.log('audio duration is ===', durationInSeconds.toFixed(2));
              parseData = { "duration": parseFloat(durationInSeconds.toFixed(2)) }
              updateMaterialJson(params, parseData);
            };
          } catch (error) {
            alert('Error loading the video: ' + error.message);
          }
        }

      })
      .catch(error => {
        console.log(error)
        document.getElementById('uploadBtn').innerHTML = 'Upload'
      });
  }

  const updateMaterialJson = async (params, parseData) => {
    let url = `/v1/materials/${params.mainJSON.material_id}.json`;
    let parentFolderID = null;
    if (params.file.type.includes(urlType)) {
      parentFolderID = urlFolderId;
    }
    if (params.file.docID) {
      parentFolderID = await params.file.docID;
    }
    let data = {
      "material": {
        // "name": params.file.name,
        "parent_folder_id": parentFolderID,
        "meta_info": JSON.stringify(parseData)
      },
      "id": params.mainJSON.material_id
    }
    axios
      .put(url, data, {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        console.log('url json is in updateMaterialJson====', res.data)
        setUpdateMaterialJson(true);
        let fileCountData = localStorage.getItem('filesCount');
        localStorage.setItem('filesCount', fileCountData - 1)
        console.log('localStorage.fileCount is = ', localStorage.getItem('filesCount'))
        if (localStorage.getItem('filesCount') != 0 && params.mainJSON.option_type === undefined) {
            fileuploading = fileuploading+1;
            uploadMedia(fileuploading)
        }
        if ((localStorage.getItem('filesCount')) == 0) {
          notify(`${contentMgmKeys["material"]} uploaded successfully.`, 'success');
          setDisabledStatus(false)
          setTimeout(() => {
            window.location = '/v2/materials'+location.search;
          }, 1000);
        }
        document.getElementById('uploadBtn').innerHTML = 'Upload'
        // document.getElementById('uploadBtn').classList.remove("disabled")
        // document.getElementById('progressbar-'+params.file.name).remove()
      })
      .catch(error => {
        console.log(error)
      });
  }
  const uploadFileData = (data, callback) => {
    let success_action_redirect = '';
    let docReplaceError = false
    console.log('uploaded data ===', data)
    let newblob = ''
    axios
      .post(`/v1/materials.json`, data, {
        headers: {
          "X-Requested-with": "XMLHttpRequest",
          contentType: "application/json;charset=utf-8",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        if(res.data.message){
          document.getElementById('replace-'+ data.material.name).classList.remove("hide")
          document.getElementById('copy-'+ data.material.name).classList.remove("hide")
          document.getElementById('error-'+ data.material.name).classList.remove("hide")
          document.getElementById('replace-note').classList.remove("hide")
          if ((localStorage.getItem('filesCount')) != 0) {
            fileuploading = fileuploading+1;
            uploadMedia(fileuploading)
          }
          // notify(res.data.file_name+' already exist','error')
          return;
        }
        if(res.data.doc_replace){
          uploadFileData(data,callback);
          docReplaceError  = true;
        }else{
          docReplaceError  = false;
        }
        let mainJSON = '';
        let thumbJSON = '';
        mainJSON = res.data[0];
        data.thumbnail ? thumbJSON = res.data[1] : '';
        success_action_redirect = mainJSON.success_action_redirect || mainJSON.callback_url;
        document.getElementById('error-'+ data.material.name).classList.add("hide")
        if(data.material.option_type !== undefined){
          res.data[0].option_type = data.material.option_type
        }
        let fileData = {
          file: data.file,
          mainJSON
        }
        callback(fileData)
        if (updateMaterial && thumbJSON !== '') {
          console.log('start thumbmail upload');
          createThumbnailBlob({
            newblob: newblob,
            file: data.file,
            size: {
              width: 100,
              height: 100
            },
            thumbJSON
          }, callback);
        }
      })
      .catch(error => {
        if(!docReplaceError){
          notify('Getting error while saving, please check the file format and size.', 'error');
        }
        document.getElementById('uploadBtn').classList.remove("disabled")
        setDisabledStatus(false)
        return;
      });
  }

  function triggerFileCreation(data) {
    let fileCountData = localStorage.getItem('filesCount');
    localStorage.setItem('filesCount', parseInt(fileCountData) - 1)
    let thisparams = data;
    if (window.noOfDocFiles !== 1) {
      try {
        console.log("Generating Files");
        document.getElementById('uploadBtn').innerHTML = 'Generating Files...';
        document.getElementById('uploadBtn').classList.add("disabled")

      } catch (e) {
        document.getElementById('uploadBtn').innerHTML = 'Upload';
        document.getElementById('uploadBtn').classList.remove("disabled")
        setDisabledStatus(false)
      }
    } else {
      document.getElementById('uploadBtn').innerHTML = 'Generating Files...';
    }
    let Folderdata = { entity_sub_type: "document", name: data.name };
    axios
      .post(`/v1/materials/folders.json`, { folder: Folderdata }, {
        headers: {
          contentType: "application/json;charset=utf-8",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(Folder_result => {
        axios
          .post(`/v1/materials/docToimg`, { data: JSON.stringify(data), Folder_result: Folder_result.data }, {
            headers: {
              contentType: "application/json;charset=utf-8",
              "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
              ).content,
            },
          })
          .then(result => {
            console.log('docToimg response is===', result)
            var allFiles = result.data.base64FileArray;
            console.log('allFiles is ==', allFiles)
            window.documentFileImageCount = allFiles.length + window.documentFileImageCount;
            var FolderIds = [result.data.folderID]
            var toUploadFile = [];
            var parentFolderIds = [];
            let docProgressbarData = [];
            var docName = result.data.docName;
            let regex = /\.\w+(?=[^.]*$)/i;
            let extractedExtension = docName.match(regex)[0];
            docName=docName.replace(extractedExtension,".pdf")
            document.getElementById('uploadBtn').innerHTML = 'Uploading...'
            let f = atob(allFiles[0]);
            let buffer = new ArrayBuffer(f.length);
            let bytes = new Uint8Array(buffer);
            for (var i = 0; i < f.length; i++) {
              bytes[i] = f.charCodeAt(i);
            }
            let blob = new Blob([bytes], { type: "application/pdf" });

            blob.name =  docName;
            blob.docID = result.data.folderID;
            toUploadFile.push(blob);
            parentFolderIds.push(FolderIds[0]);
            let fileCountData = localStorage.getItem('filesCount');
            localStorage.setItem('filesCount', parseInt(fileCountData) + 1)
            setFileData(blob)
            let filelist=files
            filelist.push({errors:undefined,file:blob,id:files.reverse()[0].id+1,name:blob.name,valid:true,size:blob.size,type:blob.type})
            setFiles(filelist);
            setUrlFolderId(blob.docID)
            let paramData = {
              'material': {
                'mime_type': blob.type,
                'name': blob.name,
                'parent_folder_id': result.data.folderID,
                'isDocImg': true,
              },
              thumbnail: false,
              file: blob
            }
            // docProgressbarData.push({'name':blob.name});
            uploadFileData(paramData, fileUploadAsync);

            // setIsDocProgress(true);
            // setdocProgress(docProgressbarData);
          })
          .catch(error => {
            notify(`Oops,something wasn't right for document ${data.name}.`, 'error');
            document.getElementById('uploadBtn').innerHTML = 'Upload';
            document.getElementById('uploadBtn').classList.remove("disabled")
            setDisabledStatus(false)
            console.log(error)
          });
        //
      })
      .catch(error => {
        notify(`Oops,something wasn't right for document ${data.name}.`, 'error');
        document.getElementById('uploadBtn').innerHTML = 'Upload';
        document.getElementById('uploadBtn').classList.remove("disabled")
        setDisabledStatus(false)
        console.log(error)
      });
  }
  const updateFiles = (incommingFiles) => {
    if(disabledStatus){
      return
    }
    let count = 0;
    const validFiles = incommingFiles.filter((file) => {
      if (count < max_file_upload_limit) {
        count++;
        return file;
      }
    });
    if (incommingFiles.length > max_file_upload_limit) {
      notify(`Maximum ${max_file_upload_limit} files are allowed to be uploaded at a time`, 'error');
    }
    fileArray = validFiles;
    setFiles(validFiles);
    localStorage.setItem('filesCount', validFiles.length)
  };
  const removeFile = (id) => {
    console.log('files is ==',files)
    fileArray = fileArray.filter((x) => x.id !== id);
    setFiles(files.filter((x) => x.id !== id));
    let fileCountData = localStorage.getItem('filesCount');
    localStorage.setItem('filesCount', fileCountData - 1);
    if(fileCountData <= '1'){
      setDisabledStatus(false)
    }
  };
  const duplicateImage = (type,name) =>{
    document.getElementById('copy-'+ name).classList.add("disabled")
    document.getElementById('replace-'+ name).classList.add("disabled")
    uploadDuplicateMedia(type,name);
  }
  const showProgressbarData = (data) => {
    return (
      <ul>
        {data.map((file, index) => (
            <>
              <div>
                <li key={index} id={'progressbar-'+file.name} className="li-progress">
                  <div>{file.name}</div>
                  < ProgressBar striped variant="success" id={file.name} now={0} />
                </li>
              </div>
              </>
        ))}
      </ul>
    )
  }
  const footerConfig = {
    style: {
      height: '4rem'
    },
    customMessage: `Supported File Formats: 
    Image:JPEG,PNG,GIF,Jfif|
    Video:MP4,MOV,Webm|
    Audio:MP3,Ogg Vorbis,WAV|
    Document:PDF,PPT,PPTX,DOCX,ZIP`,
  }
  const notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg);
        break;
      case 'error':
        toast.error(msg);
        break;
    }
  }
  const allowedTypes = ['image/png', 'image/jpeg', 'image/gif', 'audio/mp3', 'audio/mpeg',
    'audio/ogg', 'audio/wav', 'video/mp4', 'video/quicktime', 'video/webm',
    'application/pdf', '.ppt', '.pptx',
    'application/zip','application/x-zip-compressed',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  return (
      <div className="uploadFile-container">
        <ToastContainer autoClose={10000} draggable limit={1} />
        <Dropzone
            onChange={updateFiles}
            value={files}
            accept={allowedTypes.toString()}
            maxFiles={max_file_upload_limit}
            maxFileSize={totalFileSize}
            label="Drag & drop files here or click to browse"
            footerConfig={footerConfig}
            clickable={!disabledStatus}
        >
          {files.map((file) => (
              <FileMosaic key={file.id} {...file} onDelete={removeFile} info preview/>
          ))}
        </Dropzone>

        {files.length > 0 &&
            <button className="btn btn-save-button center" id="uploadBtn" onClick={()=>{uploadMedia(0)}}>Upload</button>}
        <div id="replace-note" className="replace-note hide">(Note: On "Replace" the file will get replaced in {contentMgmKeys["content"]}, Playlist and Schedule)</div>
        <div>
          <ProgressBarUplaod data={files} duplicateImage={duplicateImage} errorMessage={errorMessage}/>
          {isDocProgress && <ProgressBarUplaod data={docProgress} duplicateImage={duplicateImage}/>}
        </div>
      </div>
  );
};

export default UploadFile;