import React, { useEffect } from "react";
import { Modal } from 'react-bootstrap';
export default function Preview(props) {
    useEffect(() => {
        setTimeout(function () {
            $('#content_preview_dialog_iframe')[0].contentWindow.postMessage(props.previewData, '*')
        }, 1000)
    }, [props.previewData]);

    const closePreview = () => {
        props.closePreview(false);
    }
    return (
        <>
            <div>
                <div className="App p-4">
                    <Modal size="lg" centered show={props.show}>
                        <Modal.Header closeButton onClick={closePreview}>
                            <Modal.Title>Preview {props.previewName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <iframe src="/viewer/index.html?&width=1920&height=1080&onlyPreview=false" id="content_preview_dialog_iframe" width="772px" height="470px"></iframe>

                            </div>
                        </Modal.Body>

                    </Modal>
                </div>
            </div>
        </>
    )
}