import React, { useState, useEffect } from "react";
import moment from 'moment';
import './sidebar.scss';

const PlayListSidebar = (props) => {
  const [isLayoutVisible, setIsLayoutVisible] = useState(false);
  const [isPlayListVisible, setIsPlayListVisible] = useState(false);
  const contentMgmKeys=props.contentMgmKeys
  function searchContent(inputData,searchBy) {
    var input, filter;
    input = document.getElementById(inputData);
    filter = input.value.toLowerCase().trim();
    let parentList = document.getElementById(searchBy);
    let parentItems = parentList.getElementsByTagName("li");
    let newList =  [];
    for (let i = 0; i < parentItems.length; i++) {
      let parentContent = (parentItems[i].querySelector("span").childNodes[0].textContent) === '' ?
          parentItems[i].querySelector("span").textContent.toLowerCase():
          parentItems[i].querySelector("span").childNodes[0].textContent.toLowerCase();
      let childUl = parentItems[i].getElementsByTagName("div");
      for(let j = 0;j<childUl.length;j++){
            newList.push(childUl[j]);
      }
      if (parentContent.includes(filter)) {
        parentItems[i].style.display = ""; // Show the element
      } else {
        parentItems[i].style.display = "none"; // Hide the element
      }
    }
    for (let j = 0; j < newList.length; j++) {
        newList[j].style.display="";
        if(newList[j].querySelector("span")!== null && newList[j].querySelector("span").childNodes[0].textContent.toLowerCase().includes(filter) ||
            newList[j].parentElement.parentElement.querySelector("span")!== null && newList[j].parentElement.parentElement.querySelector("span").textContent.toLowerCase().includes(filter)){
          newList[j].parentElement.parentElement.style.display = "";
        }
        else{
          newList[j].style.display="none";
        }
    }

  }
  const onDrag = (ev, file,type) => {
    if (props.handleOnDragSidebarEnd) {
      props.drag(ev, file,type);
    }
  }
  const listContentData = () => {
    let folder = [];
    for (const key in props.contentData[0]) {
      if (props.contentData[0].hasOwnProperty(key)) {
        folder.push(key);
      }
    }
    return folder
  }
  const listPlaylistData = () => {
    let folder = [];
    for (const key in props.playListData[0]) {
      if (props.playListData[0].hasOwnProperty(key)) {
        folder.push(key);
      }
    }
    return folder
  }
  useEffect(() => {
    if(props.playListData !== undefined) {
      let toggler = document.getElementsByClassName("caret1");
      let i;
      for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function () {
          this.parentElement.querySelector(".nested1").classList.toggle("active");
          this.classList.toggle("caret-down1");
        });
      }
    }
  }, [props.playListData,isPlayListVisible])
  useEffect(() => {
    let toggler = document.getElementsByClassName("caret");
    let i;
    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }
  }, [isLayoutVisible])
  const checkExpire = (expireDate) =>{
    let htmlText = '';
    let currentDate = moment(new Date());
    let givenDate = moment(expireDate);
    if (expireDate !== null && currentDate.diff(givenDate, 'days') > 0) {
        htmlText = <span className="red">Expired</span>
    }
    return htmlText;
  }
  const getData = (action, data, expireDate) => {
    let htmlText = '';
    switch (action) {
      case 'approval_request':
      {
        let currentDate = moment(new Date());
        let givenDate = moment(expireDate);
        if (expireDate !== null && currentDate.diff(givenDate, 'days') > 0) {
          return htmlText;
        }
        if (data !== null && data.toLowerCase() === 'not requested') {
          htmlText = <span className="red">{data}</span>
        } else if (data === null) {
          htmlText = <span className="red">Not Requested</span>
        } else if (data !== null && data.toLowerCase() === 'draft') {
          htmlText = <span className="red">Not Approved</span>
        }
        else if (data !== null && data.toLowerCase() === 'approved') {
          htmlText = <span className="green">Approved</span>
        }
        else {
          htmlText = <span className="red text-capitalize">{data}</span>
        }
        return htmlText;
      }
      default:
    }
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  const showDuration = (time) => {
    if (time > 0) {
      let seconds = Math.floor(time / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      seconds = seconds % 60;
      minutes = minutes % 60;
      hours = hours % 24;

      return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
          seconds,
      )}`;
    } else {
      return '00:00:00'
    }
  }
  return (
  <>
    <div className="playList-sidebar-container">
      <div className="playList-sidebar">
        {! props.isSmartPlaylist &&
          (<div className="content-part">
             <h6 className="sidebar-header caret2" onClick={(e) => {
                e.target.classList.toggle("caret-down2")
                setIsLayoutVisible(!isLayoutVisible)
            }}>{contentMgmKeys["content"]}</h6>
            {isLayoutVisible && (
                <div>
                    <input type="text" className="sidebar-search" id="myInput" onKeyUp={() => {
                      searchContent('myInput', 'contentSearch')}} placeholder={"Search " + contentMgmKeys["content"] + "..."} title={"Type " + contentMgmKeys["content"] + " name"}></input>
          <div id="contentSearch" className="sidebar-content-data" >
            {
            listContentData().map((folderName) => (
                <ul id="myUL" key={folderName} className="myUL">
                  {
                    folderName == 'shared' ?
                    <li id="contentFolderLI" key={folderName} title={folderName}>
                    <span className="btn sidebar-content content-sidebar caret">
                      <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{folderName}</span>
                      <ul className="nested folderData" key={folderName}>
                        {
                         props.contentData[0] &&
                         props.contentData[0][folderName].map((fname,i) => (
                          <li id="contentFolderLI" key={Object.keys(fname)[0]} title={Object.keys(fname)[0]}>
                          <span className="btn sidebar-content content-sidebar caret">
                            <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{Object.keys(fname)[0]}</span>
                            <ul className="nested folderData" key={Object.keys(fname)[0]}>
                              {
                                props.contentData[0] &&
                                props.contentData[0][folderName][i][Object.keys(fname)[0]].map((file, index) => (
                                    <div key={file.id} id={file.id} className="fc-event"
                                        draggable="true" onDragStart={(ev) => { onDrag(ev, file,'content') }} type="content" data-id={file.id} title={file.name}>
                                      <span className="btn sidebar-content content-sidebar">{file.name}<p className="sidebar-duration">{showDuration(file.duration)} {checkExpire(file.end_date)} {props.contentApproval && getData('approval_request', file.approval_status, file.end_date)}</p></span>
                                    </div>
                                ))
                              }
                              </ul></li>
                         ))

                         }
                       </ul>
                       </li>

                  : folderName !== 'no_folder' ?
                      <li id="contentFolderLI" key={folderName} title={folderName}>
                      <span className="btn sidebar-content content-sidebar caret">
                        <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{folderName}</span>
                        <ul className="nested folderData" key={folderName}>
                          {
                              props.contentData[0] &&
                              props.contentData[0][folderName].map((file, index) => (
                                  <div key={file.id} id={file.id} className="fc-event"
                                      draggable="true" onDragStart={(ev) => { onDrag(ev, file,'content') }} type="content" data-id={file.id} title={file.name}>
                                    <span className="btn sidebar-content content-sidebar">{file.name}<p className="sidebar-duration">{showDuration(file.duration)} {checkExpire(file.end_date)} {props.contentApproval && getData('approval_request', file.approval_status, file.end_date)}</p></span>
                                  </div>
                              ))
                          }
                        </ul>
                      </li>

                      : props.contentData[0][folderName].map((file, index) => (
                          <li key={file.id} id={file.id} className="fc-event contentSearch"
                              draggable="true" onDragStart={(ev) => { onDrag(ev, file,'content') }} type="content" data-id={file.id} title={file.name}>
                            <span className="btn sidebar-content content-sidebar">{file.name}<div className="sidebar-duration">{showDuration(file.duration)} {checkExpire(file.end_date)} {props.contentApproval && getData('approval_request', file.approval_status, file.end_date)}</div></span>
                          </li>
                      ))
                  }
                </ul>
            ))}
          </div>
        </div>)}
        </div>
        )}

        {
            props.playListData &&
            (
            <div className="playlist-part">
                <h6 className="sidebar-header caret3" onClick={(e) => {
                    e.target.classList.toggle("caret-down3")
                    setIsPlayListVisible(!isPlayListVisible)
                }}>
                    PlayList
                </h6>
                {isPlayListVisible && (
                <div>
                <input type="text" className="sidebar-search" id="myPlaylistInput" onKeyUp={()=>{searchContent('myPlaylistInput','playlistSearch')}} placeholder="Search playlist..." title="Type name"></input>
              <div id="playlistSearch" className="sidebar-playlist-data">
                {
                listPlaylistData().map((folderName) => (
                    <div id="playlist_ul" key={folderName} className="playlist_ul">
                      {
                      folderName == 'shared' ?
                         <li id="playlistFolderLI" key={folderName} title={folderName}>
                         <span className="btn sidebar-content playlist-sidebar caret1">
                           <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{folderName}</span>
                           <ul className="nested1" key={folderName}>
                             {
                              props.playListData[0] &&
                              props.playListData[0][folderName].map((fname,i) => (
                              <li id="playlistFolderLI" key={Object.keys(fname)[0]} title={Object.keys(fname)[0]}>
                              <span className="btn sidebar-content playlist-sidebar caret1">
                                <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{Object.keys(fname)[0]}</span>
                                <ul className="nested1" key={Object.keys(fname)[0]}>
                                   {
                                      props.playListData[0] &&
                                      props.playListData[0][folderName][i][Object.keys(fname)[0]].map((file, index) => (
                                          <div key={file.id} id={file.id} className="fc-event"
                                              draggable="true" onDragStart={(ev) => { onDrag(ev, file,'playlist') }} type="playlist" data-id={file.id} title={file.name}>
                                                 <span className="btn sidebar-content playlist-sidebar">{file.name}<p className="sidebar-duration">{showDuration(file.duration)}{file.approval_status}</p></span>                                  </div>
                                      ))
                                  }
                                </ul>
                              </li>
                              ))

                              }
                            </ul>
                            </li>

                      : folderName && folderName !== 'no_folder' ?
                          <li id="playlistFolderLI" key={folderName} title={folderName}><span className="btn sidebar-content playlist-sidebar caret1">
                          <i className="fa fa-folder folder-icon" aria-hidden="true"></i>{folderName}</span>
                            <ul className="nested1" key={folderName}>
                              {
                                  props.playListData[0] &&
                                  props.playListData[0][folderName].map((file, index) => (
                                      <div key={file.id} id={file.id} className="fc-event"
                                          draggable="true" onDragStart={(ev) => { onDrag(ev, file,'playlist') }} type="playlist" data-id={file.id} title={file.name}>
                                        <span className="btn sidebar-content playlist-sidebar">{file.name}<p className="sidebar-duration">{showDuration(file.duration)}{file.approval_status}</p></span>
                                      </div>
                                  ))
                              }
                            </ul>
                          </li>
                          :
                          props.playListData[0][folderName].map((file, index) => (
                              <li key={file.id} id={file.id} className="fc-event playlistSearch"
                                  draggable="true" onDragStart={(ev) => { onDrag(ev, file,'playlist') }} type="playlist" data-id={file.id} title={file.name}>
                                <span className="btn sidebar-content playlist-sidebar">{file.name}<p className="sidebar-duration">{showDuration(file.duration)}{file.approval_status}</p></span>
                              </li>
                          ))
                      }
                    </div>)

              )}
            </div>
                </div>
            )}
      </div>)

        }


    </div >
    </div>
  </>
  );
};
export default PlayListSidebar;
