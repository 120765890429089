import { Controller } from "stimulus";
export default class extends Controller {

    password(e) {
        var password_field = $(e.currentTarget).next().find('input')[0];
        var icon = $(e.currentTarget).closest("svg")[0];

        if (password_field.type === "password") {
            icon.classList.remove('fa-eye-slash');
            icon.classList.add('fa-eye');
            password_field.type = "text";
        } else {
            icon.classList.remove('fa-eye');
            icon.classList.add('fa-eye-slash');
            password_field.type = "password";
        }
    }
}
