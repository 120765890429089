import axios from 'axios';
const API_BASE_URL = '/v2/'; // Replace with your API base URL
const HEADER_DATA= {
    contentType: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": document.querySelector(
        "meta[name='csrf-token']"
    ).content
}
export default function fetchData(apiDetails,data,setApiResponse) {
    axios
        .get(API_BASE_URL+apiDetails, {
            headers:HEADER_DATA ,
        })
        .then(res => {
            setApiResponse(res.data)
            return  res.data;
        })
        .catch(error => {
            console.log(error)
        })
};
