var errormsgflag = 0
var tooltip_timeout = 0
window.addEventListener('load' , handle_login_page);
window.addEventListener('turbo:render', handle_login_page);

var crypto = require('crypto'),
    algorithm = 'aes-256-cbc',
    key = '5bc71f37565719ad62e1709f37c44001', // 32 Characters
    iv = "0000000000000000"; // 16 Characters

function handle_login_page() {
  $("#reset_password").prop('disabled', true);
  $("#cnfPassword").prop('disabled', true);
  $(".pwd-info-icon").hover(function () {
    $('.tooltip-box').addClass("show");
    clearTimeout(tooltip_timeout)
  }, function () {
    tooltip_timeout = setTimeout(function () {
      $('.tooltip-box').removeClass("show");
    }, 500);
  });

  $('#resetPasswordlink').click(function () {
    window.location = "/users/password/new"
  });

  window.$('#otpModal').modal({
    backdrop: 'static',
    keyboard: false
  })

  $("#otp_form_buttons").addClass('d-none');
  $("#verify_otp").addClass('d-none');

  $('#user_email_forgot').change(function () {
    $("#forgot_password").removeClass("inactive");
    $("#forgot_password").removeClass("disabled");
  });

  $('#user_email_forgot').keyup(function () {
    $("#forgot_password").removeClass("inactive");
    $("#forgot_password").removeClass("disabled");
  });

  $('#cnfPassword').keyup(function () {
    var new_password_value = $("#newPassword").val();
    var cnf_password_value = $("#cnfPassword").val();
    if (cnf_password_value == new_password_value) {
      $("#reset_password").removeClass("inactive");
    } else {
      $("#reset_password").addClass("inactive");
    }
  });

  $('.btn.new-pass').on('click', function () {
    var targetField = $(this).attr('target-field');
    var input = $('#' + targetField);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#new-pass-icon").addClass('d-none');
      $("#blue-new-pass-icon").removeClass('d-none');
    } else {
      input.attr("type", "password");
      $("#new-pass-icon").removeClass('d-none');
      $("#blue-new-pass-icon").addClass('d-none');
    }
  });

  $('.btn.cnf-pass').on('click', function () {
    var targetField = $(this).attr('target-field');
    var input = $('#' + targetField);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#cnf-pass-icon").addClass('d-none');
      $("#blue-cnf-pass-icon").removeClass('d-none');
    } else {
      input.attr("type", "password");
      $("#cnf-pass-icon").removeClass('d-none');
      $("#blue-cnf-pass-icon").addClass('d-none');
    }
  });

  $('.btn.login-pass').on('click', function () {
    var targetField = $(this).attr('target-field');
    var input = $('#' + targetField);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
      $("#login-icon").addClass('d-none');
      $("#blue-login-pas-icon").removeClass('d-none');
    } else {
      input.attr("type", "password");
      $("#login-icon").removeClass('d-none');
      $("#blue-login-pas-icon").addClass('d-none');
    }
  });

  window.remember_me = function () {
    if (document.forms[0].user_remember_me.checked == true) {
      localStorage.setItem("chkbox", "true")
      localStorage.setItem("user_email", document.forms[0].user_email.value)
      localStorage.setItem("userPassword", document.forms[0].userPassword.value)
    } else {
      localStorage.setItem("chkbox", "false")
      localStorage.setItem("username", "")
      localStorage.setItem("password", "")
    }
  }
  login_form = $('#LowGer_inn')
  if (login_form.length) {
    if (localStorage.chkbox == "true") {
      login_form[0].user_remember_me.checked = true
      login_form[0].user_email.value = localStorage.user_email
      login_form[0].userPassword.value = localStorage.userPassword
    } else {
      login_form[0].user_remember_me.checked = false
    }


    if (localStorage.userPassword != "") {
      if (login_form[0].userPassword.value == localStorage.userPassword) {
        $("#send_otp").removeClass("inactive");
        $("#send_otp").removeClass("disabled");
      } else if (login_form[0].userPassword.value == "") {
        $("#send_otp").removeClass("inactive");
        $("#send_otp").removeClass("disabled");
      } else if (localStorage.userPassword == undefined) {
        $("#send_otp").removeClass("inactive");
        $("#send_otp").removeClass("disabled");
      } else {
        $("#send_otp").addClass("inactive");
        $("#send_otp").addClass("disabled");
      }
    }
  }
  $('#send_otp').click(function () {
    var email = $('#user_email').val();
    var enc_email = enc_256_string(email)
    var password = $('#userPassword').val();
    var enc_password = enc_256_string(password)
    $("#user_login_otp").val("");
    $("#otp_message").val("");

    window.$("#otpModal").modal('show');
    $.ajax({
      url: "/v1/users/send_otp",
      type: "POST",
      data: {email: enc_email, password: enc_password, encryptFlag: false},
      success: function (result) {
        window.$("#otpModal").modal('hide');
        $(".expireOtpModalClose").click();
        if (result[0] == true) {
          timer(60);
          $('#loginForm').addClass('d-none');
          $('#OtpForm').removeClass('d-none');
          $('#send_otp').addClass('d-none');
          $("#otp_form_buttons").removeClass('d-none');
          $("#verify_otp").removeClass('d-none');
          $("#submit_otp").addClass("disable-links");
          $("#submit_otp").addClass("disabled");
          $("#useremailid").html($("#user_email").val())
          $("#userphone").html(result[1])
          $('#user_login_otp1')[0].focus();
          setTimeout(function () {
            window.$("#otpModal").modal('hide');
          }, 1200);
        } else if (result[0] == "frequent") {
          setTimeout(function () {
            window.$("#otpModal").modal('hide');
            $('#otp_message').attr('style', 'color: orange !important; font-size:13px !important;margin-left:13px !important;margin-bottom:23px ');
            $(".frequent_otp_msg").removeClass("d-none");
            $("#otp_message").text("OTP requested frequently, Try again after 1 minute.");

          }, 1200);
          setTimeout(function () {
            window.$("#otpModal").modal('hide');
            $("#otp_message").hide();
          }, 60200);
          $("#otp_message").show();
        } else {
          setTimeout(function () {
            window.$("#otpModal").modal('hide')
            $("#otp_message").html('<span><i class="fa fa-times-circle" aria-hidden="true"></i> Bad Email Password Combination.</span>');
            $("#otp_message").css('color', 'red');
            $("#otp_message").show();

          }, 1200);
          $("#user_email").val("");
          $("#userPassword").val("");
          $("#send_otp").addClass("inactive");
          $("#send_otp").addClass("disabled");
        }
      }
    });
    $('#user_login_otp1').val('');
    $('#user_login_otp2').val('');
    $('#user_login_otp3').val('');
    $('#user_login_otp4').val('');
    $('#user_login_otp5').val('');
    $('#user_login_otp6').val('');
  });


//Begining of login otp timer
  let timerOn = true;

  function timer(remaining) {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    document.getElementById('timer').innerHTML = m + ':' + s;
    remaining -= 1;

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    } else {
      $(".otp_timer_msg").hide()
      $("#submit_otp").css("margin-left", "300px")
    }
    if (!timerOn) {
      // Do validate stuff here
      return;
    }
    // Do timeout stuff here
    $("#submit_otp").removeClass("disable-links");
    $("#submit_otp").removeClass("disabled");
  }

//ending of login otp timer


    var property = document.getElementById('verify_otp');
    $('.otp-input-field').keyup(function (e) {
      $("#wrong_otp_message").html('');
      let key = e.keyCode || e.charCode;
      if (key == 8 || key == 46 || key == 37 || key == 40) {
        // Backspace or Delete or Left Arrow or Down Arrow
        $(this).prev().focus();
      } else if (key == 38 || key == 39 || $(this).val() != "") {
        // Right Arrow or Top Arrow or Value not empty
        $(this).next().focus();
      }
      $("#verify_otp").removeClass("inactive");
      $("#verify_otp").removeClass("disabled");
      var val = $('#user_login_otp1').val() + $('#user_login_otp2').val() + $('#user_login_otp3').val() + $('#user_login_otp4').val() + $('#user_login_otp5').val() + $('#user_login_otp6').val();
      val = val.length
      if (val >= 6) {
        $("#verify_otp").attr("disabled", false);
        property.style.backgroundColor = "#56bdeb";
      } else {
        $("#verify_otp").attr("disabled", true);
        property.style.backgroundColor = "grey";
      }
    });

    $('#user_email_forgot').keyup(function () {
      $('.email-error').empty()
      $('#user_email_forgot').css("border-color", "black")
    })

    $('.otp-input-field').on('paste', function (e) {
      $("#wrong_otp_message").html('');
      let paste_data = e.originalEvent.clipboardData.getData("text");
      let paste_data_splitted = paste_data.split("");
      $.each(paste_data_splitted, function (index, value) {
        $('.otp-input-field').eq(index).val(value);
      });
      $("#verify_otp").removeClass("inactive");
      $("#verify_otp").removeClass("disabled");
      var element = this;
      setTimeout(function () {
        var val = $(element).val().length;
        if (val >= 6) {
          $("#verify_otp").attr("disabled", false);
          property.style.backgroundColor = "#56bdeb";
        } else {
          $("#verify_otp").attr("disabled", true);
          property.style.backgroundColor = "grey";
        }
      }, 5);
    });

    $('#user_email').keyup(function () {
      $("#otp_message").html('');
      $("#forgot_password").removeClass("inactive");
      $("#forgot_password").removeClass("disabled");
    });

    $('#userPassword').keyup(function () {
      $("#otp_message").html('');
      if (document.forms[0].userPassword.value == "") {
        $("#send_otp").addClass("inactive");
        $("#send_otp").addClass("disabled");
      } else {
        $("#send_otp").removeClass("inactive");
        $("#send_otp").removeClass("disabled");
      }
    });

    $('#newPassword').keyup(function () {
      $("#reset_password").removeClass("inactive");
      var OTPmsg = testPassword(this.value, $('#cnfPassword').val(), 0);
      var errormsgflag = OTPmsg.errormsgflag
      if (errormsgflag == 1) {
        $("#cnfPassword").prop('disabled', true)
        $('#newPassword').attr('style', 'border-color: red !important; margin-left:12px !important');
      } else {
        $("#cnfPassword").prop('disabled', false)
        $('#newPassword').attr('style', 'border-color: black !important; margin-left:12px !important');
        $("#OTPmsg").empty();
      }

      $("#OTPmsg").empty();
      if (OTPmsg.log) {
        $("#OTPmsg").html('<i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;' + OTPmsg.log);
      } else {
        $("#OTPmsg").empty();
      }
    });

    $('#newPassword').keypress(function (e) {
      if (e.which == 13) {
        $("#cnfPassword").select()
      }

    });

    $('#cnfPassword').keyup(function () {
      $("#reset_password").removeClass("inactive");
      var OTPmsg = testPassword(this.value, $('#newPassword').val(), 1);
      $("#txtmsg").empty();
      $("#txtmsg").html(OTPmsg.log);
    });


    $('#submit_otp').click(function () {
      var email = $('#user_email').val();
      var enc_email = enc_256_string(email)
      var password = $('#userPassword').val();
      var enc_password = enc_256_string(password)



      $("#submit_otp").addClass("disable-links");
      $("#submit_otp").addClass("disabled");
      $('#user_login_otp').val('');
      $.ajax({
        url: "/v1/users/send_otp",
        type: "POST",
        data: {email: enc_email, password: enc_password, encryptFlag: false},
        success: function (result) {
          if (result[0] == true) {
            $(".otp_timer_msg").show()
            $("#submit_otp").css("margin-left", "0")
            timer(60);
            $('#loginForm').addClass('d-none');
            $('#OtpForm').removeClass('d-none');
            $('#send_otp').addClass('d-none');
            $("#otp_form_buttons").removeClass('d-none');
            $("#verify_otp").removeClass('d-none');
          } else if (result[0] == "frequent") {
            $("#otp_message").text("OTP requested frequently, Try again after 1 minute.");
            setTimeout(function () {
              $("#otp_message").hide();
            }, 60200);
            $("#otp_message").css('color', 'orange');
            $("#otp_message").show();
          } else {
            $("#otp_message").text('<span><i class="fa fa-times-circle" aria-hidden="true"></i> Bad Email Password Combination.</span>');
            $("#otp_message").show();
          }
        }
      });
      $('#user_login_otp1').val('');
      $('#user_login_otp2').val('');
      $('#user_login_otp3').val('');
      $('#user_login_otp4').val('');
      $('#user_login_otp5').val('');
      $('#user_login_otp6').val('');
    });

  $("#edit_user").submit(function(e){

    var newPassword = $('#newPassword').val();
    var enc_newPassword = enc_256_string(newPassword)
    var cnfPassword = $('#cnfPassword').val();
    var enc_cnfPassword = enc_256_string(cnfPassword)
    $("#newPassword").val(enc_newPassword);
    $("#cnfPassword").val(enc_cnfPassword);
  });

    $('#verify_otp').click(function () {

      var email = $('#user_email').val();
      var login_otp = $('#user_login_otp1').val() + $('#user_login_otp2').val() + $('#user_login_otp3').val() + $('#user_login_otp4').val() + $('#user_login_otp5').val() + $('#user_login_otp6').val();
      $('#user_login_otp').val(login_otp);
      var password = $('#userPassword').val();
      var enc_password = enc_256_string(password);
      var enc_email = enc_256_string(email);
      var enc_otp = enc_256_string(login_otp);
      $.ajax({
        url: "/v1/users/authenticate_login_otp",
        type: "POST",
        data: {email: enc_email, login_otp: enc_otp},
        success: function (result) {

          if (result[0] == true) {
            $("#otp_form_buttons").removeClass('d-none');
            $("#verify_otp").removeClass('d-none');
            localStorage.setItem('x_csrf_token', result.x_access_token);
            $('#user_email').val(enc_email)
            $('#user_login_otp').val(enc_otp);
            $('#userPassword').val(enc_password)
            document.forms["LowGer_inn"].submit();
          } else if (result[0] == false) {
            $("#wrong_otp_message").html('<span><i class="fa fa-times-circle" aria-hidden="true"></i> You Entered Wrong OTP.</span>');
            $("#wrong_otp_message").show();
          } else if (result[0] == "expired") {
            $('#expireOtpId').click();
          } else if (result[0] == "no_user") {
            $("#otp_message").text('<span><i class="fa fa-times-circle" aria-hidden="true"></i> Bad Email Password Combination.</span>');
            $("#otp_message").show();
          }
        }
      });
    });

    $('#user_email').change(function () {
      activate_on_key_press();
    });
    $('#user_password').change(function () {
      activate_on_key_press();
    });


  function activate_on_key_press() {
    if (document.forms[0].user_remember_me.checked == true) {
      localStorage.setItem("chkbox", "true");
      localStorage.setItem("user_email", document.forms[0].user_email.value);
      localStorage.setItem("userPassword", document.forms[0].userPassword.value);
    } else {
      localStorage.setItem("chkbox", "false")
      localStorage.setItem("username", "")
      localStorage.setItem("password", "")
    }
  }

  function CheckSequence(s) {

    if (s) {
      var test = (x) => !isNaN(x);
      var check = (x, y, i) => x + i === y;

      for (var i = 0; i < s.length - 2; i++) {
        if (test(s[i])) {
          if (test(s[i + 1]) && test(s[i + 2])) {
            if (check(Number(s[i]), Number(s[i + 1]), 1) &&
                check(Number(s[i]), Number(s[i + 2]), 2)) {
              return true;
            }
          }
        } else if (!test(s[i + 1]) && !test(s[i + 2])) {
          if (check(s.charCodeAt(i), s.charCodeAt(i + 1), 1) &&
              check(s.charCodeAt(i), s.charCodeAt(i + 2), 2)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  function testPassword(passwd, passwd1, idflag) {
    var email = current_user_email
    var errormsgflag = 0
    var errormsgflag1 = 0
    var intScore = 0
    var strVerdict = "weak"
    var strLog = ""

    if (idflag == 1) {
      if (passwd != passwd1) {
        strLog = strLog + '<span><i class="fa fa-times-circle" aria-hidden="true"></i> Password does not match.</span>'
        errormsgflag1 = 1
      }
      if (errormsgflag1 == 1) {
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
        $('#cnfPassword').attr('style', 'border-color: red !important; margin-left:12px !important');

      } else {
        $("#reset_password").prop('disabled', false)
        $("#reset_password").removeClass("inactive");
        $('#cnfPassword').attr('style', 'border-color: black !important; margin-left:12px !important');

      }
    } else {
      // PASSWORD LENGTH
      var user_email = current_user_email.split('@')[0].split(/(\d+)/)[0].toLowerCase()

      passwderr = passwd.toLowerCase().split(/(\W)/).map(function(p){
      if( (p && user_email.includes(p))|| p.includes(user_email)) return 1
        else return 0
      })
      if (passwderr.includes(1) && passwd.length >3){
        errormsgflag = 1
        intScore = (intScore + 3)
        strLog = strLog + "Password same as user name or email is not allowed.&nbsp;"      //user name or email can not use as a password
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }
       if (passwd.length < 8 || passwd.length > 15)                         // length 8 or more
      {
        errormsgflag = 1
        intScore = (intScore + 3)
        strLog = strLog + "Required "+ (passwd.length<8 ? "minimum 8 " :  " maximum 15 " ) +"characters.&nbsp;"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }


      // LETTERS (Not exactly implemented as dictacted above because of my limited understanding of Regex)
      if (!passwd.match(/[a-z]/))                              // [verified] at least one lower case letter
      {
        errormsgflag = 1
        intScore = (intScore + 1)
        strLog = strLog + "Atleast one lowercase characters.&nbsp;"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }

      if (!passwd.match(/[A-Z]/))                              // [verified] at least one upper case letter
      {
        errormsgflag = 1
        intScore = (intScore + 5)
        strLog = strLog + " Atleast one uppercase characters.&nbsp;"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }

      // NUMBERS
      if (!passwd.match(/\d+/))                                 // [verified] at least one number
      {
        errormsgflag = 1
        intScore = (intScore + 5)
        strLog = strLog + "Atleast one number.&nbsp;"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }

      if (CheckSequence(passwd))             // [verified] at least three numbers
      {
        errormsgflag = 1
        intScore = (intScore + 5)
        strLog = strLog + "No sequential inputs like abc/123 allowed.&nbsp;"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }


      // SPECIAL CHAR
      if (!passwd.match(/.[!,@,%]/))            // [verified] at least one special character
      {
        errormsgflag = 1
        intScore = (intScore + 5)
        strLog = strLog + "At least one special character (!, @, %) is required.\n"
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }

      // [verified] at least two special characters
      if (!passwd.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)) {
        intScore = (intScore + 5)
      }

      // [verified] letters, numbers, and special characters
      if (!passwd.match(/([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/)) {
        intScore = (intScore + 2)
      }
      if (passwd != passwd1) {
        $('#cnfPassword').val("")
        $("#reset_password").prop('disabled', true)
        $("#reset_password").addClass("inactive");
      }


      if (intScore < 16) {
        strVerdict = "very weak"
      } else if (intScore > 15 && intScore < 25) {
        strVerdict = "weak"
      } else if (intScore > 24 && intScore < 35) {
        strVerdict = "mediocre"
      } else if (intScore > 34 && intScore < 45) {
        strVerdict = "strong"
      } else {
        strVerdict = "stronger"
      }
    }

    return {
      score: intScore,
      verdict: strVerdict,
      log: strLog,
      errormsgflag: errormsgflag
    };
  }

  function enc_256_string(value) {
    var salt = randomString();
    var cipher = crypto.createCipheriv(algorithm,salt,iv)
    var crypted = cipher.update(value,'utf-8',"base64")
    crypted += cipher.final("base64");
    return salt + crypted;
  }

  function randomString() {
    var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return [...Array(32)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
  }

  if ($('#LowGer_inn').length && $('#LowGer_inn')[0].user_remember_me.checked !== true) {
    if ($("input#userPassword").length){  
      $("input#userPassword").val("");
    }  
    if ($("input#user_email").length){
      $("input#user_email").val("");
    }
  }

}