import {ProgressBar} from "react-bootstrap";
import React from "react";
const ProgressBarUplaod = (props) => {
    console.log('props.errorMessage is ==', props.errorMessage)
    return (
        <ul>
            {props.data.map((file, index) => (
                <>
                    <div>
                        <li key={index} id={'progressbar-'+file.name} className="li-progress">
                            <div>{file.name} <span id={'error-' + file.name} className="hide red">(File name already exists. Please choose to save as a copy or replace existing file)</span></div>
                            < ProgressBar striped variant="success" id={file.name} now={0} />
                        </li>
                        <span className="duplicate-btn">
                          <span id={'replace-' + file.name} className="btn action-upload-btn hide" title="Replace" onClick={()=>{props.duplicateImage('replace',file.name)}}>
                              <i className="fas fa-exchange-alt" aria-hidden="true"></i>
                          </span>
                          <span id={'copy-' + file.name} className="btn action-upload-btn hide" title="Copy" onClick={()=>{props.duplicateImage('copy',file.name)}}>
                              <i className="fa fa-clone fa-1x" aria-hidden="true"></i>
                          </span>
                        </span>
                    </div>
                </>
            ))}
        </ul>
    )
}
export default ProgressBarUplaod;