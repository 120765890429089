import React, { useState,useEffect } from 'react';
import Select from "react-select";
import './dropdown.scss';
import fetchData from "./api/api";
export default function DropDown(props) {
    const [selectedOption2, setSelectedOption2] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [selectOption,setSelectOption] = useState(null);
    useEffect ( ()=>{
        if(props.apiDetails){
           fetchData(props.apiDetails,'',setApiResponse)
        }
    },[props.apiDetails])
    const listFolderData = (data) => {
        let folder = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                folder.push(key);
            }
        }
        return folder
    }
    const handleChangeSelect = (selectedOption2) => {
        setSelectedOption2(selectedOption2 );
        props.setSelectedDropdownValue(selectedOption2.value)
    };
    const showIcon = ()=>{
        return (
            <>
                <i className="fa fa-folder folder-icon" aria-hidden="true"></i>
            </>
        )
    }
    useEffect(() => {
        if (apiResponse !== null){
            let optionsData = []
            listFolderData(apiResponse).map((folderName) => {
                let optionsListData = []
                apiResponse[folderName].map((file, index) => {
                    optionsListData.push(
                        {
                            'label':file.name,
                            'value':file.id
                        }
                    )
                })
                optionsData.push({
                    'label' :folderName === 'no_folder' ?'--': `${folderName}`,
                    'options': optionsListData
                })
            })
            setSelectOption(optionsData)
        }
    },[apiResponse])
    return (
        <>
            <div className="dropdown-schedule">
                <Select
                    width="100%"
                    value={selectedOption2}
                    onChange={handleChangeSelect}
                    options={selectOption}
                    placeholder="Replace"
                />
            </div>
        </>
    );
}