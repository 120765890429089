import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@material-ui/core";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from '@mui/material';
import TableBody from "@material-ui/core/TableBody";
import Table from "@mui/material/Table";
import axios from "axios";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "./helpers/StrictModeDroppable";
import PlaylistSidebar from './PlayListSidebar';
import CommonModal from './CommonModal';
import './playlist.scss';
import Duration from './Duration';
import PreviewModal from './Preview';

function Playlist(props) {
  const contentMgmKeys=props.content_mgm_keys
  const [listData, setListData] = useState(props.playlist.items);
  const [contentData, setContentData] = useState([]);
  const [playListName, setPlayListName] = useState(props.playlist.name);
  const [editBtn, setEditBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [dropDone, setDropDone] = useState(false)
  const [fileData, setFileData] = useState([])
  const [confirmationData, setConfirmatioData] = useState('')
  const [preview, setPreview] = useState([])
  const [showPreview, setShowPreview] = useState(false);
  const [anyChanges, setAnychanges] = useState(false);
  const [savePopup, setSavePopup] = useState(false)
  const [statusVal, setStatus] = useState('')
  const [confirmationSave, setConfirmationSave] = useState('')
  const [newContentData, setNewContentData] = useState([]);
  const [contentApproval, setContentApproval] = useState(false);
  const [previewTitle, setPreviewTitle] = useState(props.playlist.name);
  const [playListData, setPlayListData] = useState([]);
  const [newPlaylistData, setNewPlaylistData] = useState([]);
  const [showContentList, setShowContentList] = useState(false)
  const [contentlistID, setContentListID] = useState('')



  const [dataType,setType] = useState([]);
  const requestForApproval = '/v2/playlists/' + props.playlist.id + '/approval_request';
  var IntervalID


  const handleOnDragEnd = (result) => {
    if (!result) return;
    const tasks = [...listData];
    const [reorderedItem] = tasks.splice(result.source.index, 1);
    tasks.splice(result.destination.index, 0, reorderedItem);
    setListData(tasks);
    setAnychanges(true);
    setConfirmationSave('')
  };
  const handleOnDragSidebarEnd = (result) => {
    if (result.name) {
      if (dropDone) {
        let orderData = getMaxtOrder(listData);
        const obj = {
          "order": orderData,
          "playlist_id": props.playlist.id,
          "content_id": result.id,
          "content_name": result.name,
          "content_count":result.content_count,
          "playlist_content_data":result.playlist_content_data,
        }
        if(dataType === 'playlist'){
           obj.sub_playlist_id = result.id;
           delete obj.content_id;
        }
        const tasks = [...listData];
        tasks.push(obj);
        setListData(tasks);
        setTimeout(function () {
          document.getElementById('uploadPlaylist').classList.remove("add-new-list")
        }, 1000);
      }
    }
    setFileData([]);
    setDropDone(false);
    setAnychanges(true);
    setConfirmationSave('')

  }
  const deletePlaylistLock=(cb)=>{
    axios
        .delete(`/v1/playlists/` + props.playlist.id + `/lock.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response  details is ====', res)
          cb()
        })
        .catch(error => {
          console.log(error)
          cb()
        });
  }
  const updatePlaylistLock=()=>{
    axios
        .put(`/v1/playlists/` + props.playlist.id + `/lock.json`, {},{
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response  details is ====', res)

        })
        .catch(error => {
          console.log(error)
        });
  }
  const deleteContent = (positionId) => {
    const tasks = [...listData];
    let resultedData = tasks.filter(task => task.order !== positionId);
    setListData(resultedData);
    setAnychanges(true);
    setConfirmationSave('')
  }
  const getMaxtOrder = (obj) => {
    if (obj.length > 0) {
      return (Math.max.apply(Math, obj.map(function (o) {
        return o.order;
      })) + 1);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (confirmationData === 'yes') {
      if (props.playlist.parent_folder_id !== null) {
        deletePlaylistLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/playlists?folder_id=' + props.playlist.parent_folder_id;

        })
      } else {
        deletePlaylistLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/playlists';
        })

      }
    }
  }, [confirmationData]);

  useEffect(() => {
    if (confirmationSave === 'yes') {
      setAnychanges(false)
      save(statusVal);
    }
  }, [confirmationSave]);

  useEffect(() => {
    axios
      .get(`/v2/contents.json`, {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        let data = [res.data]
        setContentData(data);
        getContentPlayListData(data,setNewContentData)
      })
      .catch(error => {
        console.log(error)
      })
  }, []);
  useEffect(() => {
    axios
        .post(`/v1/playlists/` + props.playlist.id + `/lock.json`, {}, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response for playlists details is ====', res)
        })
        .catch(error => {
          console.log(error)
        })
  }, []);

    useEffect(() => {
    axios
        .get(`/v2/playlists.json?playlist`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          let data = [res.data]
          setPlayListData(data);
          getContentPlayListData(data,setNewPlaylistData)
        })
        .catch(error => {
          console.log(error)
        })
  }, []);
  const getContentPlayListData = (data,stateValue) => {
    let listData = [];
    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        const items = data[0][key];
        for (const item of items) {
          listData.push(item);
        }
      }
    }
    let duplicate = false
    for (const key in data[0]) {
      if (data[0].hasOwnProperty(key)) {
        const items = data[0][key];
        for (const item of items) {
          for (const i in item) {
            if(Array.isArray(item[i]) && item[i].length >0){
              for (const j of item[i]) {
                for (const i of listData) {
                  if(i.id===j.id){
                    duplicate =  true
                  }
                }
                if(!duplicate){
                  listData.push(j);
                }
              }
            }
          }
        }
      }
    }
    stateValue(listData)
  }
  useEffect(() => {
    fetchData();
  }, [props.playlist.id]);

  useEffect(() => {
    if (contentApproval && !props.smart_playlist) {
      if (anyChanges) {
        document.getElementById('requestForApproval').classList.add("disabled")
      } else {
        document.getElementById('requestForApproval').classList.remove("disabled")
      }
    }
  }, [anyChanges]);

  const fetchData = () => {
    axios
      .get(`/v2/playlists/` + props.playlist.id + `.json`, {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        setListData(res.data.items);
        props.playlist.items = res.data.items;
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    setContentApproval(appConfig.useContentApproval);
    IntervalID=setInterval(updatePlaylistLock,180000)
  }, []);
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  const showData = (id, column) => {
    if (newPlaylistData.length > 0) {
      for (let i = 0; i < newPlaylistData.length; i++) {
        if (newPlaylistData[i].id && newPlaylistData[i].id === id && !newPlaylistData[i].hasOwnProperty('start_date')) {
          if (column === 'duration') {
            if (newPlaylistData[i].duration > 0) {
              let seconds = Math.floor(newPlaylistData[i].duration / 1000);
              let minutes = Math.floor(seconds / 60);
              let hours = Math.floor(minutes / 60);

              seconds = seconds % 60;
              minutes = minutes % 60;
              hours = hours % 24;

              return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
                  seconds,
              )}`;
            } else {
              return '00:00:00'
            }
          }
        }
      }
    }
    if (newContentData.length > 0) {
      for (let i = 0; i < newContentData.length; i++) {
        if (newContentData[i].id && newContentData[i].id === id && newContentData[i].hasOwnProperty('start_date')) {
          switch (column) {
            case 'duration':
              if (newContentData[i].duration > 0) {
                let seconds = Math.floor(newContentData[i].duration / 1000);
                let minutes = Math.floor(seconds / 60);
                let hours = Math.floor(minutes / 60);
                seconds = seconds % 60;
                minutes = minutes % 60;
                hours = hours % 24;
                return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
                  seconds,
                )}`;
              } else {
                return '00:00:00'
              }
              // return durationData;
              break;
            case 'start_date':
              return newContentData[i].start_date;
              break;
            case 'end_date':
              return newContentData[i].end_date;
              break;
            case 'approval_status':
              let htmlText = ''
              if (newContentData[i].approval_status !== null && newContentData[i].approval_status.toLowerCase() === 'not requested') {
                htmlText =<span className='text-danger text-capitalize'>{newContentData[i].approval_status}</span>
              } else if (newContentData[i].approval_status === null) {
                htmlText = <span className='text-danger'>Not Requested</span>

              } else if (newContentData[i].approval_status !== null && newContentData[i].approval_status.toLowerCase() === 'draft') {
                htmlText = <span className='text-danger'>Not Approved</span>
              }
              else if (newContentData[i].approval_status !== null && newContentData[i].approval_status.toLowerCase() === 'approved') {
                htmlText = <span className='text-success'>Approved</span>

              }
              else {
                htmlText =<span className='text-danger text-capitalize'>{newContentData[i].approval_status}</span>
              }
              return htmlText;
            case 'expire':
              {
                if (newContentData[i].end_date !== null) {
                  let A = moment(new Date());
                  let B = moment(newContentData[i].end_date);
                  if (A.diff(B, 'days') <= 0) {
                    return <span className='green-dot' title="Not Expire">✓</span>;
                  } else {
                    return <span className='red-dot' title="Expire">✘</span>;
                  }
                } else {
                  return '--';
                }
              }
            default:
          }
        }
      }
    }
  }
  const save = (status) => {
    if (!anyChanges && status) {
      notify('There is no any changes to save.', 'error');
      return;
    }
    if (playListName === '') {
      notify('Playlist name is required', 'error');
      return;
    }
    let saveData = {};
    saveData = status ?
      {
        v2_playlist: {
          name: playListName,
        },
        items: listData
      } : {
        v2_playlist: {
          name: playListName,
        }
      };
    if (anyChanges && confirmationSave !== 'yes' && status) {
      setSavePopup(true);
      setStatus(status);
    } else {
      axios
        .put(`/v2/playlists/` + props.playlist.id, saveData, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
              "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          if (res.data.status) {
            notify(res.data.msg, 'success')
            fetchData();
            if (status) {
              setAnychanges(false);
              setStatus('');
            }
            setEditBtn(false);
            document.getElementById('nameEdit').classList.add("input-span")
          } else {
            console.log('error msg', res.data.msg)
            if(res.data.msg == 'Name Only letters, numbers and _ are allowed.'){
              res.data.msg =  "Only numbers, letters and _ arFe allowed "
            }
            notify(res.data.msg.toString(), 'error')
          }
        })
        .catch(error => {
          console.log(error)
          notify('Getting error,please try again later.', 'error')
        })

    }
  }

  const handleContentList = (index) => {
      setShowContentList(true);
      setContentListID(index)
  }
  const files = (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="todos">
        {(provided) => (
          <section id="uploadPlaylist" className='drop-table' {...provided.droppableProps} ref={provided.innerRef} onDrop={(e) => { drop(e) }} onDragOver={(e) => { allowDrop(e) }}>
            <TableContainer className="playlist-table">
              <Table
                aria-label="simple table" className="playlist-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sno.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Expiry Status</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    listData.length > 0 ? listData.map((file, index) => (
                        <>
                        <Draggable
                          key={file.order}
                          draggableId={file.order.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow key={file.order} className="todos"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}>
                              <TableCell >{file.order + 1}</TableCell>
                              <TableCell>{file.content_name}<div>{contentApproval && file.content_id && showData(file.content_id, 'approval_status')}</div>
                              </TableCell>
                              <TableCell>{showData(file.content_id, 'start_date')}</TableCell>
                              <TableCell>{showData(file.content_id, 'end_date')}</TableCell>
                              <TableCell>
                                {file.sub_playlist_id ? showData(file.sub_playlist_id, 'duration'):showData(file.content_id, 'duration')}</TableCell>
                              <TableCell>
                                {file.content_id && showData(file.content_id, 'expire')}
                              </TableCell>
                              <TableCell>
                                <div>
                                  {file.sub_playlist_id ? (
                                      <span className="playlistText" title="Content list" onClick={() => {handleContentList(index)}}>
                                        Playlist ({file.content_count}) <i className="fa fa-info-circle" aria-hidden="true"></i>
                                      </span>) : (<span className="contentText" >{contentMgmKeys["content"]}</span>)}
                                </div>
                                {showContentList }
                              </TableCell>
                              <TableCell className="font-blue-color">
                                <span>
                                  <a data-turbo-frame="remote_modal" onClick={() => {
                                    file.sub_playlist_id ? callPreview(file.sub_playlist_id, 'playlist', file.content_name) : callPreview(file.content_id, 'content', file.content_name) }}>
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  </a>
                                </span>
                                <span className="action-button" onClick={() => { deleteContent(file.order) }}>
                                  <i className="fa fa-trash" aria-hidden="true" ></i>
                                </span>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable >
                        </>
                    )) : <TableRow><TableCell colSpan={12} className="no_record">Drag content here</TableCell></TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
            {provided.placeholder}
          </section>
        )}
      </Droppable>
    </DragDropContext >
  );
  const notify = (msg, type) => {

    switch (type) {
      case 'success':
        toast.success(msg);
        break;
      case 'error':
        toast.error(msg);
        break;
    }
  }
  const savePlaylistName = (e) => {
    if (playListName !== e.target.value) {
      setEditBtn(true)
    }
    setPlayListName(e.target.value);
  }
  const closePlaylist = () => {
    if (anyChanges) {
      setShowPopup(true)
    } else {

      if (props.playlist.parent_folder_id !== null) {
        deletePlaylistLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/playlists?folder_id=' + props.playlist.parent_folder_id;

        })
      } else {
        deletePlaylistLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/playlists';
        })

      }
    }
  }

  function allowDrop(ev) {
    setDropDone(true);
    ev.preventDefault();
  }
  function drag(ev, file,type) {
    setFileData(file)
    setType(type)
    ev.dataTransfer.setData("text", ev.target.id);
    return true;
  }
  function drop(ev) {
    let error = false
     listData.map((files, index) => {
      if(files.sub_playlist_id === fileData.id){
          error  = true;
          notify(`${files.content_name} playlist already exist`, 'error');
          return;
      }
    })
    if(window.location.href.includes(fileData.id)){
      error = true
      notify(`Same playlist can't be add as subplaylist`, 'error');
      return;
    }
    if(listData.length >= appConfig.playlistMaxlimit){
      error = true;
      notify(`Maximum ${appConfig.playlistMaxlimit} contents are allowed`, 'error');
      return;
    }
    ev.preventDefault();
    if(!error)
    handleOnDragSidebarEnd(fileData);
    document.getElementById('uploadPlaylist').classList.add("add-new-list");
  }
  const callPreview = (id, type, name) => {
    let previewurl = '';
    if (name !== '') {
      setPreviewTitle(name)
    }
    previewurl = type === 'playlist' ? '/v2/preview.json?id=' + id + '&amp;type=playlist'
      : '/v2/preview.json?id=' + id + '&amp;type=content';
    axios
      .get(previewurl, {
        headers: {
          contentType: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector(
            "meta[name='csrf-token']"
          ).content,
        },
      })
      .then(res => {
        setPreview(res.data)
        setShowPreview(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const nameEdit = () => {
    document.getElementById('nameEdit').classList.remove("input-span")
  }
  let listData1;
  listData1=listData.map(function(data){
    return data.playlist_content_data
  })
  return (
    <>
      <ToastContainer />
      <div className="playListContainer">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-2">
              {contentData.length > 0 && <PlaylistSidebar contentApproval={contentApproval} drop={drop} dropDone={dropDone} allowDrop={allowDrop} drag={drag} contentMgmKeys={contentMgmKeys}
                handleOnDragSidebarEnd={handleOnDragSidebarEnd} contentData={contentData} playListData={playListData} isSmartPlaylist={props.smart_playlist}/>}
            </div>
            <div className="col-sm-10 col-md-10 col-lg-10">
              {showPreview && <PreviewModal previewData={preview} show={showPreview} closePreview={setShowPreview} previewName={previewTitle} />}
              <div>
                {showPopup && <CommonModal setShowPopup={setShowPopup} show={showPopup}
                  title={'Confirmation'} body='There are some unsaved changes in this playlist, are you sure want to close ?' setConfirmatioData={setConfirmatioData} />}
                {savePopup && <CommonModal setShowPopup={setSavePopup} show={savePopup}
                  title={'Confirmation'} body='Are you sure want to save your changes? ' setConfirmatioData={setConfirmationSave} />}
                <div className="d-flex justify-content-between playlist-header">
                  <div>
                    <span className="input-icom" onClick={nameEdit}><i className="fas fa-edit"></i></span>
                    <input className='input-span' onDrop={(e) => { e.preventDefault() }} id="nameEdit" type="text" contentEditable="true" value={playListName} onChange={savePlaylistName} onFocus={() => { document.getElementById('nameEdit').classList.remove("input-span") }} maxLength={40} />
                    {editBtn && <button className="small-btn" onClick={() => { save(false) }}>Update</button>}
                  </div>
                  <div className="d-flex justify-content-between gap-3 align-items-center">
                    { props.smart_playlist && <div className="smart-playlist-text">Smart Playlist<span className="icon-smart-playlist" title="Condition based playlist sequence"><i className="fa fa-info-circle icon-size"></i></span></div>
                  }
                    {contentApproval && !props.smart_playlist &&<div>
                      <a className="btn btn-save-button" id="requestForApproval" data-turbo-frame="remote_modal" href={requestForApproval}>
                        <span className="ms-2 ">Request for approval</span>
                      </a>
                    </div>
                    }
                    <button className="btn btn-save-button" onClick={() => { save(true) }}> Save</button>
                    {props.playlist.items.length > 0 && <div><span className="btn btn-close-button pointer" title='preview' onClick={() => { callPreview(props.playlist.id, 'playlist', props.playlist.name) }}><i className="fas fa-play-circle"></i></span></div>}
                    <span className="btn btn-close-button pointer" title='close' onClick={closePlaylist}> <i className="fa fa-times" aria-hidden="true"></i></span>
                    {newContentData.length > 0 && <div><span title='duration' className="duration-icon"><i className="fas fa-clock duration-icon"></i><Duration listData={listData} contentData={newContentData} playlistData={newPlaylistData}/></span></div>}
                  </div>
                </div>
              </div>
              <aside className="table-data">
                <div style={{ marginTop: "0rem" }}>{files}</div>
              </aside>

              <CommonModal closeBtn={true} setShowPopup={setShowContentList}
                           setShowContentList={setShowContentList} show={showContentList}
                           title={'Content List'}
                           body=''
                           playlistcontentlist={listData1}
                           contentlistID={contentlistID}
                           footer=''/>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
export default Playlist;
