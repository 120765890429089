import React, { useState } from "react";
export default function Duration(props) {
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
      const getDuration = (time) => {
        if (time > 0) {
          let seconds = Math.floor(time / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          seconds = seconds % 60;
          minutes = minutes % 60;
          hours = hours % 24;
    
          return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds,
          )}`;
        } else {
          return '00:00:00'
        }
      }
    const showData = () => {
        let durationArray = [];
        props.contentData.filter(function (o1) {
            return props.listData.some(function (o2) {
                if (o1.id === o2.content_id) {
                    if (o1.duration !== '' || o1.duration > 0) {
                        durationArray.push(o1.duration);
                    }
                }
            });
        })
        var sum = 0;
        durationArray.forEach(item => {
            if(item !== undefined){
             sum += parseInt(item);
            }
        });
        let playlistDurationArray = [];
        props.playlistData.filter(function (o1) {
            return props.listData.some(function (o2) {
                if (o1.id === o2.sub_playlist_id) {
                    if (o1.duration !== '' || o1.duration > 0) {
                        playlistDurationArray.push(o1.duration);
                    }
                }
            });
        })
        playlistDurationArray.forEach(item => {
            if(item !== undefined){
                sum += parseInt(item);
            }
        });
        return getDuration(sum);
    }
    return (
        <>
            {showData()}
        </>
    );
}
