import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["displayParts"];
  static values = { interval: Number };

connect() {
  const loadFunction = () => {
    console.log("first time load screeen caputred called")
    this.loadScreenCaptured();
    this.intervalSelectTarget.addEventListener("change", this.handleChange.bind(this));
  };

  if (this.displayPartsTarget) {
        console.log("display targert present")
        loadFunction();
      } else {
        console.log("first time load function call")
        setTimeout(loadFunction, 100);
      }
  }

  loadScreenCaptured() {
    const intervalSelected = this.intervalSelectTarget.value;
    console.log("start update interval")
    this.startUpdateInterval(intervalSelected); // Start interval with initial dropdown value
  }

  updateScreenCaptured(intervalSelected) {
    fetch('/v2/displays/screen_captures?displays_list=true')
      .then(response => response.json())
      .then(result => {
        // this.displayPartsTarget.innerHTML = "";
        // result.forEach(display => {
        //         const hdmiIcon = ["Disconnected","HDMI Disconnected", "Not Available", "Not Applicable"].includes(display.hdmi_status)
        //   ? `<label class="tick"><img src="/images/connection.png" class="icon_red icon_mark" title="${display.hdmi_status}" /></label>`
        //   : '';
        //   const cardHtml = `
        //     <div class="col-sm-3 col-md-3 col-lg-3">
        //       <div id="customer_parts">
        //         <a href="/v2/displays/${display.id}/screen_capture_preview" data-turbo-frame="remote_modal" class="card-link">
        //           <div class="card content-card1 create-template-card">
        //             <div style="text-align: center;">
        //               ${display.screen_capture}
        //             </div>
        //             <span class="text_color sub-heading"> Display ID : ${display.id}${hdmiIcon}</span>
        //             <span class="text_color sub-heading text_trim"> Display Name : ${display.name}</span>
        //             <span class="text_color sub-heading"> Screen Last Update : ${display.last_updated_at}</span>
        //           </div>
        //         </a> 
        //       </div>
        //     </div>`;
        //   this.displayPartsTarget.insertAdjacentHTML("beforeend", cardHtml);
        // });
      })
      .catch(error => console.error("Error updating screen captured:", error));
  }

  // startUpdateInterval(intervalSelected) {
  //   console.log("interval selected called")
  //   if (this.intervalId) {
  //     clearInterval(this.intervalId);
  //   }
  //   if (intervalSelected != undefined) {
  //     this.intervalId = setInterval(() => {
  //       this.updateScreenCaptured(intervalSelected);
  //     }, intervalSelected * 60000);
  //   }
  // }

  startUpdateInterval(intervalSelected) {
    this.intervalId = setInterval(() => {
      this.updateScreenCaptured(intervalSelected);
    }, intervalSelected * 60000);
    console.log("interval selected selcted", intervalSelected)
    console.log("intervalId", this.intervalId)
  }

  stopUpdateInterval() {
    clearInterval(this.intervalId);
  }

  handleChange(event) {
    console.log("handle change")
    const intervalSelected = event.target.value;
    this.stopUpdateInterval(); // Stop previous interval
    this.startUpdateInterval(intervalSelected); // Start new interval with new dropdown value
  }

  get intervalSelectTarget() {
    return this.context.element.querySelector("#intervalSelect");
  }

  get displayPartsTarget() {
    return this.context.element.querySelector(".display_parts");
    console.log("Display parts target:", target);
    return target;
  }

  disconnect() {
    // Check if the current tab is the screen capture tab
    if (this.isScreenCaptureTab()) {
      return;
    }
    this.intervalSelectTarget.removeEventListener("change", this.handleChange);
    this.stopUpdateInterval();
  }

  isScreenCaptureTab() {
    return window.location.pathname.includes('/v2/displays/screen_captures');
  }
}
