/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jquery = jQuery;

import Chart from 'chart.js/dist/Chart';
global.Chart = Chart;
import flatpickr from 'flatpickr/dist/flatpickr';
global.flatpickr=flatpickr

require("@popperjs/core")
require("bootstrap")
require("select2")
require("./dashboard")

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const trkDatatables = require('trk_datatables')

if (!window.__appClientLoaded) {
    window.__appClientLoaded = true;

    require('@hotwired/turbo');
}
import "controllers"
Turbo.setConfirmMethod((message, element) => {
    let dialog = document.getElementById("turbo-confirm")
    dialog.querySelector("p").textContent = message
    dialog.showModal()

    return new Promise((resolve, reject) => {
        dialog.addEventListener("close", () => {
            resolve(dialog.returnValue == "confirm")
        }, { once: true })
    })
})


document.addEventListener('turbo:render', () => {
    render_dataTable();
    ReactRailsUJS.mountComponents()
    $('.trk-move-up ').css('margin-top', '-40px');
})

document.addEventListener('turbo:before-fetch-request', () => {
   console.log('turbo:before-visit')
   $('.overlay, body').removeClass('loaded');
   $('.overlay').show()
})

document.addEventListener('turbo:before-stream-render', () => {
    $('.overlay, body').addClass('loaded');
    $('.overlay').css({'display':'none'})
})
document.addEventListener('turbo:frame-render', () => {
    render_dataTable();
    ReactRailsUJS.mountComponents()
    $('.trk-move-up ').css('margin-top', '-40px');
})
document.addEventListener('DOMContentLoaded', () => {
    render_dataTable();
})

function render_dataTable(){
    trkDatatables.initialise({

        lengthMenu: [10, 20, 50, 100, 200],
        // scrollY: true,
        scrollX: false,
        pagingType: 'simple',
        oLanguage: {
            search: '',
            searchPlaceholder: 'Search...',
            Info : 'END of TOTAL',
            LengthMenu: 'Show entries MENU',
            paginate: {
                next: '<i class="fa fa-angle-right">',
                previous: '<i class="fa fa-angle-left">' ,
            }
        },
        dom: '<"trk-global-search-wrapper"f>rtp<"trk-move-up"li>'
    })
    var checkd_checkboxes = 0;
    $("#toggle-all-rows").click(function() {
        var all_checkboxes;
        all_checkboxes = $("[data-enable-buttons]");
        if (checkd_checkboxes > 0) {
            checkd_checkboxes = 0
            return all_checkboxes.prop("checked", false);
        } else {
            all_checkboxes.prop("checked", true);
            checkd_checkboxes = all_checkboxes.length
            return
        }
    });

    $(document).on('click', '[data-enable-buttons]', function(e) {
        var $target;
        $target = $($(e.currentTarget).data().enableButtons);
        return checkIfEnableButtons($target);
    });
    $('.overlay, body').addClass('loaded');
    $('.overlay').css({'display':'none'})
}

window.select2Init = function () {
    $('[data-select2-initialize]').each( function (){
        $(this).select2({
            dropdownParent: $("#shareRecipeModal"),
            closeOnSelect : !$(this)[0].multiple,
            allowClear: true
            // allowHtml: true,

        });
    });
    $.fn.select2.defaults.set("width", "100%");
};

window.dateValidation = function (){
    $("#start_date").change(function(){
        var start_date =  $("#start_date").val();
        $("#end_date").prop('min',start_date);
    });
}

window.searchBarCursorPosition = function (){
    var search_field = $('#search_id')[0]
    var start_end_range = search_field.value.length
    search_field.focus();
    search_field.setSelectionRange(start_end_range, start_end_range,"forward");
}

window.checkIfEnableButtons = function($target) {
    if ($('[data-enable-buttons]:checked').length > 0) {
        $target.attr("disabled", false)

    } else {
        $target.attr('disabled',true);

    }
};

$(document).ready(function () {
    $(".nav-item").click(function () {
        $(".dropdown-toggle").removeClass("show");
        $(".profile-bg").removeClass("show");
        // $(".dropdown-toggle").removeClass("active");
        $("ul").removeClass("show");
        $("#navbarDropdownMenuLink").removeAttr("aria-expanded");
    });
});



import "@fortawesome/fontawesome-free/js/all";

import './../stylesheets/application.scss'
import './login'

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
