// This is a replacement for the built-in Rails ujs confirmation method when using Bootstrap.
// Trying to hook into the built-in stuff is a pain, and changes with every Rails (now Hotwire)
// version.
import { Controller } from "stimulus";

export default class extends Controller {
    static values = {
        title: String,
        okButton: String,
        cancelButton: String,
    }

    connect() {
        // console.log('Confirm controller connect');

    }

    click(event) {
        let title = this.titleValue;
        let ok = this.okButtonValue;
        if (!ok) { ok = 'OK'; }
        let cancel = this.cancelButtonValue;
        if (!cancel) { cancel = 'Cancel'; }
        let dialog = document.getElementById("turbo-confirm")
        dialog.querySelector("h4").textContent = title
        dialog.querySelector(".btn-confirm").textContent = ok

    }

}