import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import { TextField } from "@mui/material/";
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
import axios from "axios";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreviewModal from './Preview';
import CommonModal from './CommonModal';
import DropDown from './DropDown';
export default function EditSchedule(props) {
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('00:00');
    const [checkbox, setCheckbox] = useState(false);
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [eventId, setEventId] = useState('');
    const [eventName, setEventName] = useState('');
    const [eventContentID, setEventContentID] = useState('');
    const [eventPlaylistID, setEventPlaylistID] = useState('');
    const [type, setType] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [preview, setPreview] = useState([])
    const [showPreview, setShowPreview] = useState(false);
    const [showDeletePopup,setShowDeletePopup] = useState(false);
    const [confirmationData,setConfirmatioData] = useState('');

    const handleChange = () => {
        setCheckbox((current) => !current);
        let checked = document.getElementById("repeatCheck").checked;
        console.log('checked===',checked)
        setCheckbox(checked)
    }
    const closePopup = () => {
        props.setSchedulePopup(false);
        setCheckbox(false)
    }
    const deleteScheduleCall = () => {
        setConfirmatioData('');
        props.deleteEvent(eventId, props.popupEvent);
    }
    const saveSchedulePopupData = () => {
        if(startTime === null){
            notify('StartTime could not be blank.','error')
            return;
        }
        if(endTime === null){
            notify('EndTime could not be blank.','error')
            return;
        }
        const arr1 = startTime.split(":"); // splitting the string by colon
        const seconds1 = arr1[0]*3600+arr1[1]*60; // converting
        const arr2 = endTime.split(":"); // splitting the string by colon
        let seconds2 = ''
        if(arr2[2]){
             seconds2 = arr2[0]*3600+arr2[1]*60+(+arr2[2]); // converting
        }
        else{
             seconds2 = arr2[0]*3600+arr2[1]*60; // converting
        }
        if((seconds1 !== 0 && seconds2 === 0) || (seconds1 >= seconds2 && seconds1 !== 0 && seconds2 !== 0)){
            notify('EndTime could not be less then or equal to StartTime.', 'error')
            return;
        }
        if ((Date.parse(startDate) > Date.parse(endDate))) {
            notify('StartDate could not be greater then EndDate.','error')
            return;
        }
        let data = {};
        data = {
            'content_id': eventContentID,
            'playlist_id': eventPlaylistID,
            'id': eventId,
            'title': eventName,
            type,
            startDate,
            startTime,
            endDate,
            endTime,
            repeat_type:checkbox
        }
        props.saveSchedulePopup(data)
        closePopup();
    }

    useEffect(() => {
        if (props.popupEvent.event) {
            let endDateTime = '';
            let startDateTime = '';
            startDateTime = moment(props.popupEvent.event.start).format('YYYY-MM-DD HH:mm:ss');
            const startDateTimeArray = startDateTime.split(" ");
            setStartTime(startDateTimeArray[1]);
            setStartDate(startDateTimeArray[0])
            endDateTime = props.popupEvent.event.end !== null ? moment(props.popupEvent.event.end).format('YYYY-MM-DD HH:mm:ss'):
            ' 00:00:00';
            const endtDateTimeArray = endDateTime.split(" ");
            setEndTime(endtDateTimeArray[1])
            if (typeof (props.popupEvent.event._def.extendedProps.repeat_type) !== undefined &&
                props.popupEvent.event._def.extendedProps.repeat_type === 'daily') {
                setCheckbox(true);
                startDateTime = moment(props.popupEvent.event._def.extendedProps.startDate).format('YYYY-MM-DD')
                endDateTime = moment(props.popupEvent.event._def.extendedProps.endDate).format('YYYY-MM-DD')
                setStartDate(startDateTime)
                setEndDate(endDateTime)
            }
            else if (props.popupEvent.event.end !== null) {
                endDateTime = moment(props.popupEvent.event.end).format('YYYY-MM-DD HH:mm:ss')
                const endDateTimeArray = endDateTime.split(" ");
                setEndTime(endDateTimeArray[1])
                setEndDate(endDateTimeArray[0])
            } else {
                endDateTime = moment(props.popupEvent.event.start).format('YYYY-MM-DD HH:mm:ss');
                const endDateTimeArray = endDateTime.split(" ");
                setEndTime(endDateTimeArray[1])
                setEndDate(endDateTimeArray[0])
            }

            // const endDateTime = moment(props.popupEvent.event._def.recurringDef.endRecur).format('YYYY-MM-DD hh:mm:ss');
            setEventId(props.popupEvent.event._def.publicId);
            setEventName(props.popupEvent.event._def.title);
            (typeof (props.popupEvent.event._def.extendedProps) !== undefined) ?
                setEventPlaylistID(props.popupEvent.event._def.extendedProps.playlist_id) : setEventPlaylistID(props.newPlaylistId);
            (typeof (props.popupEvent.event._def.extendedProps) !== undefined) ?
                setEventContentID(props.popupEvent.event._def.extendedProps.content_id) : setEventContentID(props.newContentId);
            setType(props.popupEvent.event._def.extendedProps.type)
            if (typeof (props.popupEvent.event._def.extendedProps.playlist_id) != 'undefined') {
                console.log('inside if' + props.popupEvent.event._def.extendedProps.playlist_id);
                (props.popupEvent.event._def.extendedProps.playlist_id !== -1) ?
                    setPreviewUrl('/v2/preview.json?id=' + props.popupEvent.event._def.extendedProps.playlist_id + '&amp;type=playlist') :

                    setPreviewUrl('/v2/preview.json?id=' + props.popupEvent.event._def.extendedProps.content_id + '&amp;type=content');
            } else {
                console.log('inside else' + props.newPlaylistId);

                (props.newPlaylistId !== -1) ?
                    setPreviewUrl('/v2/preview.json?id=' + props.newPlaylistId + '&amp;type=playlist') :

                    setPreviewUrl('/v2/preview.json?id=' + props.newContentId + '&amp;type=content');
            }

        }
    }, [props.popupEvent.event]);
    const handleStartDate = (e) => {
        setStartDate(e.target.value)
    }
    const handleEndDate = (e) => {
        setEndDate(e.target.value)
    }
    const notify = (msg, type) => {
        switch (type) {
          case 'success':
            toast.success(msg);
            break;
          case 'error':
            toast.error(msg);
            break;
        }
      }
    const callPreview = () => {
        closePopup();
        axios
            .get(previewUrl, {
                headers: {
                    contentType: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRF-Token": document.querySelector(
                        "meta[name='csrf-token']"
                    ).content,
                },
            })
            .then(res => {
                setPreview(res.data)
                setShowPreview(true)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleEndTime = (e) => {
        setEndTime(e.target.value)
    }
    const handleStartTime = (e) => {
        setStartTime(e.target.value)
    }

    const showDeleteConfirmation=()=>{
        closePopup();
        setShowDeletePopup(true)
    }
    useEffect(() => {
        if (confirmationData === 'yes') {
            deleteScheduleCall();
        }
    }, [confirmationData]);

    return (
        <div>
            <ToastContainer />
            {showPreview && <PreviewModal previewData={preview} show={showPreview} closePreview={setShowPreview} previewName='Schedule' />}
            {showDeletePopup && <CommonModal setShowPopup={setShowDeletePopup} show={true}
                                       title={'Confirmation'} body='Are you sure you want to delete this schedule?'
                                       setConfirmatioData={setConfirmatioData} />}
            <div className="App p-4">
                <Modal size="lg" centered show={props.show} onHide={closePopup}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    {type && <DropDown apiDetails={`${type}s.json`} setSelectedDropdownValue={type === 'content'? setEventContentID:setEventPlaylistID} />}
                                </div>
                                <div className="col-sm-6">
                                    <span><h5 className="scheduleName">{eventName}</h5></span>
                                </div>
                                <div className="col-sm-2">
                                    <button className="btn btn-save-button" data-turbo-frame="remote_modal"
                                            onClick={() => {
                                                callPreview()
                                            }}>Preview
                                    </button>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Start Time</label>
                                <div className="col-sm-4">
                                    {/*<div className="App">*/}
                                    {/*    <TimePicker className="react-clock" onChange={setStartTime}*/}
                                    {/*                value={startTime} closeClock={true} format={'HH:mm'}*/}
                                    {/*                disableClock={true}/>*/}
                                    {/*</div>*/}
                                    <TextField
                                        id="startTime"
                                        variant="standard"
                                        fullWidth
                                        type="time"
                                        value={startTime}
                                        onChange={handleStartTime}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">End Time</label>
                                <div className="col-sm-4">
                                    {/*<div className="App">*/}
                                    {/*    <TimePicker className="react-clock" onChange={setEndTime}*/}
                                    {/*                value={endTime} closeClock={true} format={'HH:mm'}*/}
                                    {/*                disableClock={true}/>*/}
                                    {/*</div>*/}
                                    <TextField
                                        id="endTime"
                                        variant="standard"
                                        fullWidth
                                        type="time"
                                        value={endTime}
                                        onChange={handleEndTime}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <input type="checkbox" className="repeatCheck" id="repeatCheck" checked={checkbox} onChange={() => handleChange()} />
                                    <label className="col-form-label repeat-check-box">Repeat This Duration</label>
                                </div>
                            </div>
                            {checkbox &&
                                <>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Start Date</label>
                                        <div className="col-sm-4">
                                            <TextField
                                                id="startDate"
                                                variant="standard"
                                                fullWidth
                                                type="date"
                                                defaultValue={startDate}
                                                onChange={handleStartDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">End Date</label>
                                        <div className="col-sm-4">
                                            <TextField
                                                id="sendDate"
                                                variant="standard"
                                                fullWidth
                                                type="date"
                                                inputProps={{ min: startDate }}
                                                defaultValue={endDate}
                                                onChange={handleEndDate}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-save-button" onClick={saveSchedulePopupData}>Save</button>
                        <button className="btn btn-save-button" onClick={closePopup}>Cancel</button>
                        <button className="btn btn-save-button" onClick={showDeleteConfirmation}>Delete</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}